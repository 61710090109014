import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { getApplicationConfig } from "./app/app.config";


getApplicationConfig().then((config) => {
    if (config == null)
        return;

    bootstrapApplication(AppComponent, config)
        .catch((err) => console.error(err))
});
