import { Component, OnInit } from "@angular/core";
import { Permissions, UserInfoService } from "@palliativ-core/services/user-info.service";
import { HeaderComponent } from "../@palliativ-core/layout/header/header.component";
import { NavComponent } from "../@palliativ-core/layout/nav/nav.component";
import { RouterOutlet } from "@angular/router";
import { FooterComponent } from "../@palliativ-core/layout/footer/footer.component";

@Component({
    selector: "body[app-root]",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    imports: [HeaderComponent, NavComponent, RouterOutlet, FooterComponent]
})
export class AppComponent implements OnInit {

    constructor(private userInfoService: UserInfoService) { }

    ngOnInit() {
        this.userInfoService.authorize();

        this.userInfoService.getUserInfo().subscribe(userInfo => {
            if (userInfo.isAdmin && !userInfo.hasPermission(Permissions.registreraDödsfall, Permissions.registreraEnhetsenkät)) {
                window.location.href = "/admin";
            }
        });
    }
}
