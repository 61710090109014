import { Routes } from "@angular/router";
import { SigninComponent, SignoutComponent, UnauthorizedComponent } from "@palliativ-core/pages";
import { AutoLoginPartialRoutesGuard } from "angular-auth-oidc-client";
import { ManageMessagesComponent } from "./pages/manageMessages/manage-messages.component";


export const routes: Routes = [
    {
        path: "",
        loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule),
        canActivate: [AutoLoginPartialRoutesGuard], data: () => {
            var impersonate = sessionStorage.getItem("impersonate");
            return impersonate != null ? { acr_values: `impersonate:${impersonate}` } : null;
        }
    },
    { path: "manageMessages", component: ManageMessagesComponent, canActivate: [AutoLoginPartialRoutesGuard] },
    { path: "signin", component: SigninComponent },
    { path: "signout", component: SignoutComponent },
    { path: "unauthorized", component: UnauthorizedComponent },
];

