import { Inject, Injectable } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { AppSettings, APP_SETTINGS } from "app/app.settings";


@Injectable({
    providedIn: "root"
})

export class UserInfoService {

    private userInfoAdded: EventEmitter<UserInfo> = new EventEmitter<UserInfo>();
    private userInfo: UserInfo = {} as UserInfo;
    private userInfo$ = new BehaviorSubject(this.userInfo);

    constructor(@Inject(APP_SETTINGS) private env: AppSettings, private oidcSecurityService: OidcSecurityService) { }

    public authorize() {
        this.oidcSecurityService.checkAuth().subscribe(loginResponse => {
            const { isAuthenticated, userData, accessToken, idToken, configId } = loginResponse;

            if (!isAuthenticated) {
                const impersonate = sessionStorage.getItem("impersonate");
                if (impersonate == null)
                    this.oidcSecurityService.authorize();
                else
                    this.oidcSecurityService.authorize(undefined, { customParams: { acr_values: `impersonate:${impersonate}` } });
            }
            else {
                this.setUserInfo(userData);
            }
        });
    }

    public unAuthorize() {
        this.oidcSecurityService.revokeAccessToken();
        this.oidcSecurityService.revokeRefreshToken();
    }

    public setUserInfo(userData: any): void {

        this.userInfo = new UserInfo();
        this.userInfo.id = userData["sub"];
        this.userInfo.name = `${userData["given_name"]} ${userData["family_name"]}`.trim();
        this.userInfo.firstName = `${userData["given_name"]}`.trim();
        this.userInfo.lastName = `${userData["family_name"]}`.trim();
        this.userInfo.hsaid = userData["hsaid"];
        this.userInfo.unit = userData["unit_name"];
        this.userInfo.unitId = userData["unit_id"];
        this.userInfo.email = userData["email"];
        this.userInfo.impersonator_id = userData["impersonator_id"] ?? 0;
        this.userInfo.isAuthenticated = (this.userInfo.id > 0);

        if ("permissions" in userData) {
            const p = userData["permissions"] as string;
            this.userInfo.permissions = p.length > 0 ? p.split(",").map(s => parseInt(s)) : [];
        }

        if ("role_name" in userData)
        {
            this.userInfo.roles = userData["role_name"];
        }

        this.userInfo.isAdmin = this.userInfo.hasPermission(Permissions.System, Permissions.Support, Permissions.Grupp);

        this.userInfoAdded.emit(this.userInfo);
        this.userInfo$.next(this.userInfo);
    }

    public getUserInfo(): Observable<UserInfo> {
        if (this.userInfo)
            return of(this.userInfo);

        return this.userInfoAdded;
    }
    public get userInfoFilter() {
        return this.userInfo$;
    }

    logout() {
        this.clearApps();
        sessionStorage.removeItem("impersonate");
        sessionStorage.setItem("signoutRedirect", this.env.authConfig.authority + "/account/logout");
        this.oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => console.log(result));
    }


    changeUnit() {
        this.clearApps();
        sessionStorage.setItem("signoutRedirect", "/home");
        this.oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => console.log(result));
    }


    impersonate(userid: number) {
        this.clearApps();
        sessionStorage.setItem("impersonate", userid.toString());
        sessionStorage.setItem("signoutRedirect", "/");
        this.oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => console.log(result));
    }

    endImpersonation() {
        this.clearApps();
        sessionStorage.removeItem("impersonate");
        sessionStorage.setItem("signoutRedirect", "/");
        this.oidcSecurityService.logoffAndRevokeTokens().subscribe((result) => console.log(result));
    }

    private clearApps(allApps = false) {
        const app = allApps ? "-" : "0-" + this.env.authConfig.clientId;
        Object.keys(sessionStorage).forEach((key) => {
            if (key != app && key.startsWith("0-")) {
                sessionStorage.removeItem(key);
            }
        });
    }
}


export class UserInfo {
    isAuthenticated = false;
    id = 0;
    name = "";
    firstName = "";
    lastName = "";
    unit = "";
    unitId = 0;
    hsaid = "";
    email = "";
    isAdmin = false;
    impersonator_id = 0;
    roles?: [];
    permissions: number[] = [];

    public hasPermission(...requestedPermissions: Permissions[]): boolean {
        return (requestedPermissions.filter(p => this.permissions.includes(p)).length > 0);
    }
}

export enum Permissions {
    odefinierat = 0,
    hanteraPatienter = 1,
    hanteraMeddelande = 2,
    hanteraAnvändare = 3,
    registreraEnhetsenkät = 4,
    registreraDödsfall = 5,
    registreraNärstående = 6,
    visaDödsfall = 7,
    skrivUtDödsfall = 8,
    hanteraDatauttag = 9,
    imiteraAnvändare = 90,
    System = 91,
    Support = 92,
    Grupp = 93,
    fullaRättigheter = 99
}
