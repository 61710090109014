import { ApplicationConfig, enableProdMode, importProvidersFrom } from "@angular/core";

import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { PalliativCoreModule } from "@palliativ-core/palliativ-core.module";
import { SharedModule } from "@shared/shared.module";
import { ToastrModule } from "ngx-toastr";
import { CoreModule } from "./core/core.module";

import { env } from "@env/env";
import { structuredMerge } from "functions/structuredMerge";
import { routes } from "./app.routes";
import { APP_SETTINGS, AppSettings } from "./app.settings";


const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(ToastrModule.forRoot({ positionClass: "toast-top-center", }), SharedModule, CoreModule, PalliativCoreModule),
        provideAnimations(),
        provideRouter(routes)
        ]
};


export async function getApplicationConfig(): Promise<ApplicationConfig|null>
{
    const baseHref = (document.getElementsByTagName("base")[0] || {}).href;

    try {
        const response = await fetch(`${baseHref}api/settings`);
        const backendSettings = await response.json();

        if (env.production) {
            enableProdMode();
            window.console.debug = function () { /* disable any console.debug debugging statements in production mode */ };
        }

        // Merge environment with settings
        const settings = new AppSettings();
        structuredMerge(settings, env, backendSettings);
        // Get href from base element
        if (settings.baseHref == "")
            settings.baseHref = baseHref;

        if (!env.production)
            console.log(settings);

        appConfig.providers.push({ provide: APP_SETTINGS, useValue: settings });

        return appConfig;
    }
    catch (error) {
        alert("Ingen kontakt med servern!");
        return null;
    }
}
