import { NgModule, Optional, SkipSelf } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { FooterComponent, HeaderComponent, NavComponent } from "./layout";
import { UserInfoService } from "./services/user-info.service";
import { AuthConfigModule } from "./auth/auth-config.module";


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FontAwesomeModule,
        NgbDropdownModule,
        HeaderComponent,
        NavComponent,
        FooterComponent
    ],
    exports: [
        HeaderComponent,
        NavComponent,
        FooterComponent,
        AuthConfigModule
    ],
    providers: [UserInfoService]
})

export class PalliativCoreModule {
    constructor(@Optional() @SkipSelf() parentModule: PalliativCoreModule) {
        if (parentModule)
            throw new Error("PalliativCoreModule has already been loaded. Import PalliativCoreModule modules in the AppModule only.");
    }
}
